<template>
  <footer data-cy-region="footer" class="bg-primary-2-07 antialiased font-display">
    <div class="FooterLinks container mx-auto grid gird-cols-1 gap-x-10">
      <div class="[ mx-6 mb-7 lg:m-0 ]">
        <h3 class="text-base capitalize font-bold">{{ $t('customerService') }}</h3>
        <ul class="mt-2 grid grid-cols-2 md:grid-cols-1 gap-2 text-base">
          <li>
            <AppLink to="/contact">{{ $t('contactUs') }}</AppLink>
          </li>
          <li>
            <AppLink to="/faqs">{{ $t('faqs') }}</AppLink>
          </li>
        </ul>
      </div>

      <div class="[ mx-6 mb-7 lg:m-0 ]">
        <h3 class="text-base capitalize font-bold">{{ $t('about') }}</h3>
        <ul class="mt-2 grid grid-cols-2 md:grid-cols-1 gap-2 text-base">
          <li>
            <AppLink to="/about">{{ $t('who') }}</AppLink>
          </li>
          <!-- TODO: Show when there are stores -->
          <!-- <li>
            <AppLink to="/stores">{{ $t('stores') }}</AppLink>
          </li> -->
          <!-- <li>
            <AppLink to="/careers">{{ $t('careers') }}</AppLink>
          </li> -->
        </ul>
      </div>

      <div class="[ mx-6 mb-7 lg:m-0 ]">
        <h3 class="text-base capitalize font-bold">{{ $t('more') }}</h3>
        <ul class="mt-2 grid grid-cols-2 md:grid-cols-1 gap-2 text-base">
          <li>
            <AppLink to="/privacy-policy" external>{{ $t('privacyPolicy') }}</AppLink>
          </li>

          <li>
            <AppLink to="/terms-and-conditions" external>{{ $t('termsAndConditions') }}</AppLink>
          </li>
        </ul>
      </div>

      <div class="md:hidden flex flex-col gap-x-2.5 [ mx-6 pb-10 ] [ border-b border-gray-300 ]">
        <h3 class="text-base capitalize font-bold">{{ $t('changeLang') }}</h3>
        <a
          :href="switchLocalePath($i18n.locale === 'en' ? 'ar' : 'en')"
          :class="[$i18n.locale === 'en' ? 'font-body-ar font-bold' : 'font-body']"
          >{{ $t('switchLang') }}</a
        >
      </div>

      <div
        class="[ mx-6 lg:mr-0 lg:ml-auto py-10 lg:py-0 ] [ border-b lg:border-0 border-gray-300 ] md:w-75 md:ml-auto z-0"
      >
        <h3 class="text-base capitalize font-medium">{{ $t('newsletter') }}</h3>
        <div class="[ flex flex-col justify-center ] relative md:mt-2">
          <form class="NewsletterForm md:mt-2" @submit="onSubmit">
            <TextInput
              id="newsletter_email"
              name="email"
              :label="$t('subscribe')"
              class="mt-2 text-base h-12"
              type="email"
              :placeholder="$t('addYourEmail')"
              aria-label="Email Address"
              rules="email"
              border
              light
              filled
              :is-optional="true"
            />
            <button
              class="w-5 h-5 absolute right-2 bottom-3 z-10 [ flex items-center justify-center ]"
              :loading="isSubmitting"
              :aria-label="$t('send')"
              disable-hover
            >
              <svg-icon-send class="w-5 h-5" />
            </button>
          </form>
        </div>
      </div>

      <div class="hidden md:flex h-unit md:w-full bg-deals-45 md:col-span-5 mx-6 md:mx-0 mt-8 mb-10" />

      <div class="md:col-start-2 md:col-end-4 [ mx-6 lg:mx-0 pt-10 lg:pt-0 ][ flex items-center justify-center ]">
        <ul class="grid grid-cols-3 gap-x-12 items-center">
          <li>
            <a
              href="https://www.facebook.com/pancoegypt/"
              aria-label="Like us on Facebook"
              target="_blank"
              rel="noopener"
            >
              <svg-icon-facebook width="30" height="30" />
            </a>
          </li>
          <li>
            <a
              href="https://www.instagram.com/panco_egypt"
              aria-label="Follow us on Instagram"
              target="_blank"
              rel="noopener"
            >
              <svg-icon-instagram width="30" height="30" />
            </a>
          </li>
        </ul>
      </div>

      <div class="hidden md:flex items-center justify-end gap-x-2.5">
        <h3 class="text-base capitalize font-bold font-body">{{ $t('changeLang') }}</h3>
        <a
          :href="switchLocalePath($i18n.locale === 'en' ? 'ar' : 'en')"
          :class="[$i18n.locale === 'en' ? 'font-body-ar font-semibold -mt-1.5' : 'font-body']"
          >{{ $t('switchLang') }}</a
        >
      </div>
    </div>

    <div class="mt-10 w-full bg-primary-2-10 mx-auto pt-4 pb-44 lg:pb-4 text-center" dir="ltr">
      <p class="md:text-center capitalize text-primary-1-60">
        ©{{ new Date().getFullYear() }} - {{ $t('appName') }} | {{ $t('copyrights') }}
      </p>
    </div>
  </footer>
</template>

<script setup lang="ts">
defineComponent({
  name: 'TheFooterMainSection',
});
const switchLocalePath = useSwitchLocalePath();

const { t: $t } = useI18n({ useScope: 'local' });

import { CombinedError } from 'villus';
import { object, string } from 'yup';

const { submitForm } = useSubscribeToNewsletter();

const { success, error } = useAlerts();

const { isSubmitting, handleSubmit, resetForm, setErrors } = useForm({
  validationSchema: object({
    email: string(),
  }),
});

const onSubmit = handleSubmit(async ({ email }) => {
  try {
    await submitForm(email);
    success($t('success').toString(), $t('newsLetterSuccess').toString());
    resetForm();
  } catch (e) {
    if (e instanceof CombinedError) {
      error('error', e.message);
      setErrors({
        email: e.message.toString(),
      });
    }
  }
});
</script>

<style lang="postcss" scoped>
footer {
  @screen md {
    height: 100%;
  }

  .FooterLinks {
    margin-top: 48px;

    @screen lg {
      grid-template-columns: 1fr 1fr 1fr 360px;
    }

    a {
      @apply relative;

      &::before {
        content: '';
        width: calc(100% + 10px);
        transform: scaleX(0);
        @apply absolute bottom-0 right-0 left-0 text-secondary-700;
        box-shadow: 0px 2px 0px 5px currentColor;
        transition: transform 0.3s ease-in-out;
      }

      /* &:hover {
        &::before {
          transform: scaleX(1);
        }
      } */
    }

    & > div:not(:last-child) {
      @apply relative;
    }
    & > div:not(:last-child)::after {
      @apply mx-auto;
      @screen md {
        content: unset;
      }
      height: 1px;
      width: calc(100% - 50px);
      background-color: #d0dedc;
      position: absolute;
      bottom: -24px;
      right: 0;
      left: 0;
    }
  }
}
.NewsletterForm {
  .AppButton {
    padding: 0;
    border: none;
    z-index: 3;
  }
}
</style>

<i18n>
{
  "en": {
    "customerService": "Customer Service",
    "faqs": "FAQs",
    "contactUs": "Contact us",
    "about": "About Panco",
    "who": "Who are we?",
    "stores": "Find Stores",
    "more": "More",
    "refunds": "Returns & Refund",
    "termsAndConditions": "Terms & Conditions",
    "subscribe": "Subscribe to our newsletter",
    "follow": "Follow us",
    "copyrights": "All Rights Reserved",
    "changeLang": "Change Language",
    "switchLang": "عربي",
    "downloadApp": "Download App",
    "weAccept": "We Accept",
    "careers": "Careers",
    "privacyPolicy": "Privacy Policy",
    "newsletter": "Newsletter",
    "beTheFirstToKnow": "BE THE FIRST TO KNOW",
    "note": "Sign up to newsletter to get latest updates about new products and exclusive discounts",
    "email": "E-mail Address",
    "addYourEmail": "example.com",
    "partnerBrand": "Partners",
    "success": "Success",
    "newsLetterSuccess": "Subscribed to newsletter successfully!",
    "error": "Subscribe Error",
    "returns": "Refunds & Returns ",
    "appName": "Panco",
    "send": "Send"
  },
  "ar": {
    "customerService": "خدمة العملاء",
    "faqs": "الأسئلة الشائعة",
    "contactUs": "اتصل بنا",
    "about": "عن الشركة",
    "who": "من نحن؟",
    "stores": "الفروع",
    "more": "المزيد",
    "refunds": "الاسترجاع",
    "termsAndConditions": "سياسة الاستخدام",
    "subscribe": "سجل للنشرة",
    "follow": "تابعنا",
    "copyrights": "جميع الحقوق محفوظة",
    "switchLang": "English",
    "downloadApp": "تحميل التطبيق",
    "weAccept": "نحن نقبل",
    "careers": "المهن",
    "privacyPolicy": "سياسة الخصوصية",
    "newsletter": "- النشرة الإخبارية",
    "beTheFirstToKnow": "كن أول من يعرف",
    "note": "اشترك بنشرتنا الإخبارية لتصلك أجدد المنتجات و عروض خاصة",
    "email": "العنوان الالكتروني",
    "addYourEmail": "أضف عنوانك الالكتروني",
    "changeLang": "تغيير اللغه",
    "partnerBrand": "العلامة التجارية الشريكة",
    "success": "نجاح",
    "newsLetterSuccess": "تم التسجيل بنجاح!",
    "error": "خطأ في الاشتراك",
    "returns": "الاسترجاع",
    "appName": "بانشو",
    "send": "ارسال"
  }
}
</i18n>
