<template>
  <header data-attr="header" class="fixed top-0 inset-x-0 main-navigation z-30 bg-primary-2-01 text-black md:pb-3">
    <div class="relative rounded-b-2xs">
      <AppHeader />
      <div class="Overlay hidden" :class="{ 'md:block': miniCartShown }" @click="miniCartShown = false"></div>
      <div class="[ flex items-center flex-wrap md:grid md:grid-cols-1 ]">
        <div class="[ header-title ] [ container mx-auto ] md:pt-9 border-b md:border-none md:border-gray-350">
          <div>
            <QuickSearch class="hidden md:flex z-20" />
          </div>
          <div class="header-mobile relative w-full">
            <AppLink to="/" data-attr="header__home__link" aria-label="application logo" class="header-logo w-full">
              <svg-icon-logo class="w-17 md:w-36 h-5 md:h-7 mx-auto" />
            </AppLink>
            <div class="md:hidden w-full">
              <SearchDialog class="header-search w-full border-l h-16 border-gray-350" />
            </div>
          </div>

          <div class="flex items-center md:space-x-6">
            <AppLink
              data-attr="header__logIn__link"
              :to="!(isLoggedIn && user?.email) ? '/login' : '/account'"
              class="ml-auto hidden order-1 lg:order-2 md:flex items-center"
              :aria-label="!(isLoggedIn && user?.email) ? $t('loginOrRegister') : $t('greet', { name: user?.name })"
            >
              <DynamicIcon :name="isLoggedIn && user?.email ? 'user-active' : 'user'" :width="24" :height="24" />
            </AppLink>
            <div class="relative order-4">
              <AppLink
                to="/cart"
                data-attr="header__cartIcon__btn"
                class="hidden md:flex items-center justify-center cursor-pointer"
                aria-label="cart"
                @mouseover="showMiniCart"
              >
                <ClientOnly>
                  <DynamicIcon :name="count ? 'cart-active' : 'cart'" :width="25" :height="25" />
                  <span v-show="count" data-attr="header__cartItemCount" class="CartIconCount">({{ count }})</span>
                  <ComposeAddToCartAlert />
                </ClientOnly>
              </AppLink>
              <CartMiniCart
                class="hidden"
                :class="{ 'md:block': miniCartShown }"
                @toggle="e => (miniCartShown = e)"
              ></CartMiniCart>
            </div>
          </div>
        </div>
        <div class="container flex">
          <ClientOnly>
            <CategoryMegaMenu class="w-full" />
          </ClientOnly>
        </div>
      </div>
    </div>
  </header>
</template>

<script setup lang="ts">
const { isLoggedIn, user } = useAuth();
const { count } = useCartAttributes();
const { t: $t } = useI18n({
  useScope: 'local',
});

defineEmits(['toggle']);
const miniCartShown = ref(false);

function showMiniCart() {
  if (!count.value) {
    return;
  }
  miniCartShown.value = true;
}
</script>

<style lang="postcss" scoped>
.CartIconCount {
  @apply rounded-full bg-transparent text-primary-1-100 text-base absolute flex items-center justify-center;
  width: 0;
  height: 0;
  top: 20px;
  right: -10px;
}

.nuxt-icon svg {
  width: 27px;
  height: 27px;
}
.Overlay {
  @apply fixed left-0 top-0 w-full bg-primary-1-100 bg-opacity-10;

  height: calc(100vh + 250px);
}
</style>

<i18n>
{
  "en": {
    "loginOrRegister": "Login or Register",
    "contactUs": "Contact us",
    "switchLang": "عربي",
    "logout":  "Logout",
    "notice": "FREE shipping on orders above 1,000 EGP",
    "customerSupport": "Customer Support",
    "greet": "Hello, {name}"
  },
  "ar": {
    "contactUs": "اتصل بنا",
    "switchLang": "English",
    "logout":  "تسجيل الخروج",
    "notice": "شحن مجاني للطلبات التي تتخطى 1,000 جنيه",
    "customerSupport": "دعم العملاء",
    "loginOrRegister": "تسجيل الدخول",
    "greet": "مرحبا، {name}"
  }
}
</i18n>

<style lang="postcss" scoped>
@screen md {
  .header-title {
    display: grid;
    grid-template-columns: 1fr;
  }
}

.header-mobile {
  @apply px-6 lg:px-0;
  display: grid;
  grid-template-columns: 124px 1fr;
  align-items: center;
}

@screen lg {
  .header-title {
    display: grid;
    grid-template-columns: 450px 1fr 450px;
  }
  .header-mobile {
    grid-template-columns: 1fr;
  }
}

:deep(.nuxt-icon svg) {
  width: 25px;
  height: 25px;
}
</style>
