<template>
  <div class="sticky-bar w-full text-xs">
    <div class="text-primary-1-100 [ w-full grid grid-cols-5 justify-between ] [ p-4 pt-0 ]">
      <AppLink to="/" class="flex items-center flex-col text-center" aria-label="home">
        <svg-icon-home-active
          v-if="[/index___en/, /index___ar/].some(route => route.test($route?.name?.toString() || ''))"
          width="28"
          height="28"
        />
        <svg-icon-home v-else width="28" height="28" />

        <span class="mt-1 px-1.5"> {{ $t('home') }}</span>
      </AppLink>

      <button
        class="categories flex items-center flex-col text-center"
        :class="{ active: /category/.test($route.path) }"
        disable-hover
        @click="toggleCategoryMenu"
      >
        <svg-icon-categories-active v-if="/category/.test($route.path)" width="28" height="28" />

        <svg-icon-categories v-else width="28" height="28" />

        <span class="mt-1 px-1.5"> {{ $t('categories') }}</span>
      </button>

      <AppLink to="/cart" class="Cart flex items-center flex-col text-center" aria-label="navigate to cart">
        <svg-icon-responsive-cart-active v-if="/cart/.test($route.path)" width="28" height="28" />
        <svg-icon-responsive-cart v-else width="28" height="28" />
        <span class="mt-1 px-1.5"> {{ $t('cart', { count }) }}</span>
      </AppLink>

      <AppLink to="/deals" class="flex items-center flex-col text-center" aria-label="navigate to deals">
        <svg-icon-sale-active-dark
          v-if="[/deals___en/, /deals___ar/].some(route => route.test($route?.name?.toString() || ''))"
          width="28"
          height="28"
        />

        <svg-icon-sale-dark v-else class="text-primary-1-100" width="28" height="28" />
        <span class="mt-1 px-1.5"> {{ $t('offers') }}</span>
      </AppLink>

      <button class="Profile flex items-center flex-col text-center" aria-label="navigate to user" @click="toAccount()">
        <svg-icon-user-active v-if="/account/.test($route.path)" width="28" height="28" />
        <svg-icon-user v-else width="28" height="28" />
        <span class="mt-1 px-1.5 whitespace-nowrap"> {{ user?.email ? $t('profile') : $t('login') }}</span>
      </button>
    </div>
  </div>
</template>
<script setup lang="ts">
const { user } = useAuth();
const { count } = useCartAttributes();
const { emit } = useEventBus();
const { redirect } = useAppRouter();

function toggleCategoryMenu() {
  emit('TOGGLE_CATEGORY_MENU');
}

const { t: $t } = useI18n({
  useScope: 'local',
});

function toAccount() {
  if (user.value) {
    emit('TOGGLE_ACCOUNT_MENU');
    return;
  }

  redirect('/login');
}
</script>
<i18n>
{
  "en": {
    "login": "Profile",
    "profile": "Profile",
    "cart": "Cart ({count})",
    "home": "Home",
    "offers": "Sale",
    "categories": "Categories"
  },
  "ar": {
    "login": "تسجيل الدخول",
    "profile": "الملف الشخصي",
    "cart": "السلة ({count})",
    "home": "الرئيسية",
    "offers": "عروض",
    "categories": "الفئات"
    }
}
</i18n>
<style lang="postcss" scoped>
.nuxt-link-exact-active svg {
  @apply text-primary-1-100;
}

a[aria-label^='navigate to'].router-link-active svg {
  @apply text-primary-1-100 font-semibold;
}

.Profile {
  @apply whitespace-nowrap;
}

.curve-icon {
  transform: translateY(calc(-100% + 2px));
  filter: drop-shadow(-3px -4px 3px rgba(215, 215, 215, 0.3));
  z-index: -1;
}

.AppButton {
  @apply font-normal;
  border: none;
  padding: 0;
}

.sticky-bar {
  background: #fff;
  box-shadow: 0px -10px 30px 0px rgba(26, 25, 25, 0.05);

  & a,
  & button {
    @apply pt-4;
  }

  & a[aria-label^='navigate to'].router-link-active,
  & a.router-link-exact-active,
  .active {
    @apply font-semibold;
  }
}

[dir='rtl'] {
  .Profile {
    @apply whitespace-normal;
  }

  a[aria-label^='navigate to'].Cart.nuxt-link-active svg,
  & > a[aria-label^='navigate to'].Cart.nuxt-link-exact-active svg,
  & > a[aria-label^='navigate to'].Cart svg {
    transform: scale(-1, 1);
  }
}

:deep(.nuxt-icon svg) {
  height: 28px;
  width: 28px;
}
</style>
