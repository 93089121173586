<template>
  <div
    class="MiniCart_container px-7 py-7 absolute top-10 right-0 z-50 bg-white shadow-md flex flex-col justify-around my-auto"
  >
    <header class="flex flex-col gap-1">
      <div class="relative flex justify-between items-center">
        <h2 class="text-lg capitalize font-bold text-center">{{ $t('cart') }}</h2>
        <button type="button" @click="handleClose">
          <svg-icon-close class="absolute -right-1 top-0" width="40" height="40" />
        </button>
      </div>

      <p class="text-sm font-normal text-primary-2-04 text-center lg:text-left">
        {{ $t('productsCount', count, [count]) }}
      </p>
    </header>

    <div data-cy="cart-items" class="CartPage__container mt-6 mb-auto flex flex-col">
      <transition-group name="list" tag="ul" class="CartPage__List max-h-[400px] overflow-y-auto px-6 pb-8 lg:px-0">
        <li
          v-for="{
            id,
            quantity,
            type,
            name,
            image,
            oldPrice,
            totalPrice,
            totalDiscount,
            stock,
            sku,
            cartControl,
            isInvalidItem,
            allowChangeQuantity,
            url,
            giftBoxMessage,
            giftBoxOptionId,
            configurationOptions,
          } in items"
          :key="id"
        >
          <CartItem
            :id="id"
            data-attr="cartPage__item"
            :quantity="quantity"
            :name="name"
            :image="image"
            :price="totalPrice - totalDiscount"
            :old-price="oldPrice * quantity"
            :stock="stock"
            :sku="sku"
            :cart-control="cartControl"
            :type="type"
            :is-invalid-item="isInvalidItem"
            :allow-change-quantity="allowChangeQuantity"
            :url="url"
            :gift-box-message="giftBoxMessage"
            :gift-box-option-id="giftBoxOptionId"
            :configuration-options="configurationOptions"
            updatable
          />
        </li>
      </transition-group>
    </div>

    <div class="ProceedCta w-full h-24 absolute left-0 bottom-0 px-7 bg-primary-2-07 flex flex-col justify-center">
      <div class="w-full flex justify-between items-center">
        <div calss="flex flex-col justify-center items-center">
          <div v-if="discount" class="flex justify-between gap-x-4">
            <span class="text-black text-sm">{{ $t('discount') }}</span>
            <Money :value="discount * -1" />
          </div>
          <div class="flex justify-between items-center gap-x-4">
            <span class="text-black text-sm">{{ $t('total') }}</span>
            <Money class="font-bold" :value="total" />
          </div>
        </div>
        <AppButton
          data-cy="go-to-checkout"
          as="AppLink"
          :to="isLoggedIn ? '/checkout/shipping' : '/login?checkout=true'"
          class="w-64 h-10 p-2 font-medium uppercase flex justify-center items-center whitespace-nowrap"
          :disabled="hasInvalidItems"
          disable-hover
          inverted
        >
          {{ $t('proceed') }}
        </AppButton>
      </div>
      <p v-if="hasInvalidItems" class="flex gap-x-1 items-center mt-1 mr-auto absolute left-10 top-15">
        <svg-icon-error class="w-3 h-4 z-20 text-red-700" />
        <span class="text-red-700 text-base">{{ $t('invalidItemsError') }}</span>
      </p>
    </div>
  </div>
</template>

<script lang="ts" setup>
const { isLoggedIn } = useAuth();
const { count, items, total, hasInvalidItems, discounts } = useCartAttributes();
const emit = defineEmits<{ (e: 'toggle', value: boolean): void }>();
const route = useRoute();

const discount = computed(() => {
  if (!discounts.value.length) return undefined;
  return (discounts.value as Array<{ value: number; label: string }>).reduce((acc, curr) => acc + curr.value, 0);
});

const { t: $t } = useI18n({
  useScope: 'local',
});

function handleClose() {
  emit('toggle', false);
}

/**
 * if user deleted items using remove button from mini cart
 * watch for the cart items count
 * if no items then toggle the mini cart
 */
watch(count, newValue => {
  if (!newValue) {
    emit('toggle', false);
  }
});

useEvent('ITEM_ADDED', () => {
  const unwatchItems = watch(items, () => {
    emit('toggle', true);
    unwatchItems();
  });
});

watch(
  () => route.path,
  () => {
    handleClose();
  },
);
</script>

<style lang="postcss" scoped>
.MiniCart_container {
  width: 520px;
  height: 600px;
  border-radius: 30px;
}
.ProceedCta {
  box-shadow: 0px -6px 20px 2px rgba(26, 25, 25, 0.05);
  border-radius: 0 0 30px 30px;
}
.CartPage__container {
  grid-template-columns: 1fr 406px;
  column-gap: 40px;
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */
  }
}

.CartPage__List {
  li + li {
    margin-top: 30px;
  }

  li:not(:first-child) {
    @apply border-t border-primary-2-02;
    padding-top: 30px;
  }
}
</style>

<i18n>
{
  "en": {
		"cart": "My Cart",
    "total": "Cart Total",
    "proceed": "Proceed to checkout",
    "productsCount": "You have {count} product in your cart | You have {count} products in your cart",
    "invalidItemsError": "Please remove out of stock items from cart.",
    "discount": "Discount"
  },
  "ar": {
		"cart": "عربة التسوق",
    "total": "الاجمالي",
    "proceed": "استمر للدفع",
    "productsCount": "لديك {count} منتج فى السله ",
    "invalidItemsError": "يرجي حذف المنتجات الغير متاحة من العربة.",
    "discount": "الخصم"
  }
}
</i18n>
