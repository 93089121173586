<template>
  <transition-group name="slide-up">
    <div
      v-if="isOpen"
      :key="1"
      class="z-30 fixed inset-x-0 bottom-0 top-10 w-full rounded-t-6sm dialog-slider flex flex-col bg-primary-50 border-primary-50 border rounded-tr-2xl rounded-tl-3xl lg:hidden"
    >
      <button
        class="text-sm [ rounded-md ] [ flex items-center justify-center ] [ w-10 h-10 ] [ absolute top-5 right-5 ] z-50"
        :title="$t('closeMenu')"
        @click="close"
      >
        <svg-icon-close class="text-primary-1-800" width="40" height="40" />
      </button>
      <div
        class="categories-modal relative pt-4 pb-8 h-full overflow-y-auto border border-primary-50 rounded-tr-3xl rounded-tl-3xl"
      >
        <header class="w-full relative [ pb-7 px-6 z-0 ] [ flex flex-col justify-between ]">
          <h3 class="mt-2 text-xl-1 font-bold">{{ $t('title') }}</h3>
        </header>

        <ul class="Category__Menu px-6">
          <li class="Category__Menu__Item">
            <AppLink to="/deals" class="flex items-center justify-between h-full text-deals-35">
              <span class="flex items-center ml-2">
                <span class="SaleIcon">
                  <svg-icon-sale-active width="24" height="24" class="w-6 h-6" />
                </span>
                {{ $t('sale') }}
              </span>
              <svg-icon-chveron-right
                class="text-primary-1-100"
                :class="{
                  'transform scale-x-reverse': $i18n.locale === 'ar',
                }"
                width="12"
                height="12"
              />
            </AppLink>
          </li>
          <li class="Category__Menu__Item">
            <AppLink to="/new-in" class="flex items-center justify-between h-full text-primary-800">
              <span class="flex items-center">
                <svg-icon-new-in width="40" height="40" class="mr-5" />

                {{ $t('newIn') }}
              </span>
              <svg-icon-chveron-right
                class="text-primary-1-100"
                :class="{
                  'transform scale-x-reverse': $i18n.locale === 'ar',
                }"
                width="12"
                height="12"
              />
            </AppLink>
          </li>
          <li v-for="category in categories" :key="category.id || 0" class="Category__Menu__Item">
            <button
              v-if="category.children && category.children.length"
              :aria-label="`navigate to ${category.name}`"
              class="flex w-full items-center justify-between"
              @click="
                selectedMenuItem = {
                  type: 'Category',
                  payload: category,
                }
              "
            >
              <div class="flex items-center">
                <NuxtImg
                  v-if="category.image"
                  :src="category.image"
                  alt="category image"
                  class="h-15 w-11 mr-4 object-contain rounded-xs rounded-bl-none"
                  format="webp"
                  width="55"
                  height="80"
                />
                <span class="text-base capitalize"> {{ category.name }} </span>
              </div>
              <svg-icon-chveron-right
                class="text-primary-1-100"
                :class="{
                  'transform scale-x-reverse': $i18n.locale === 'ar',
                }"
                width="12"
                height="12"
              />
            </button>
            <button
              v-else
              class="flex w-full items-center justify-between"
              @click="navigateTo(`/category/${category.url_key}`)"
            >
              <div class="flex items-center">
                <span class="text-sm capitalize"> {{ category.name }} </span>
              </div>
              <svg-icon-chveron-right
                class="text-primary-1-100"
                :class="{
                  'transform scale-x-reverse': $i18n.locale === 'ar',
                }"
                width="12"
                height="12"
              />
            </button>
          </li>
        </ul>
      </div>
      <transition name="slide-up">
        <section
          v-if="selectedMenuItem"
          :key="2"
          class="StackMenu z-40 bg-primary-50 overflow-y-auto pt-8 pb-16 rounded-t-3xl"
        >
          <template v-if="selectedMenuItem">
            <button class="px-6 flex items-center" @click="selectedMenuItem = null">
              <svg-icon-chveron-right
                class="float-right text-primary-900 mr-3"
                :class="{
                  'transform scale-x-reverse': $i18n.locale === 'en',
                }"
                width="12"
                height="12"
              />
              <span class="text-base capitalize underline">{{ $t('allCategories') }}</span>
            </button>
            <header class="w-full [ px-6 mt-7 z-0 ] [ flex flex-col justify-between ]">
              <h3 class="text-xl-1 font-body font-bold capitalize">{{ selectedMenuItem?.payload?.name }}</h3>
            </header>

            <nav class="w-full relative z-40">
              <ul class="text-secondary-900 Subategory__Menu px-6 mt-8">
                <li v-for="(category, index) in (selectedMenuItem?.payload as Category)?.children" :key="index">
                  <div class="flex justify-between items-center">
                    <button
                      class="flex flex-col w-full justify-center"
                      @click="navigateTo(`/category/${category.url_key}`)"
                    >
                      <span class="category-name text-base capitalize"> {{ category.name?.toLowerCase() }} </span>
                    </button>
                    <svg-icon-chveron-right
                      class="text-primary-1-100"
                      :class="{
                        'transform scale-x-reverse': $i18n.locale === 'ar',
                      }"
                      width="12"
                      height="12"
                    />
                  </div>
                </li>
              </ul>
            </nav>

            <button
              class="flex mt-10 px-6 md:px-0 w-full justify-between items-center tex-sm capitalize underline"
              @click="navigateTo(`/category/${selectedMenuItem.payload?.url_key}`)"
            >
              {{ $t('shopAll', { name: selectedMenuItem.payload?.name }) }}
            </button>
          </template>
        </section>
      </transition>
    </div>
  </transition-group>
</template>

<script setup lang="ts">
import type { Brand, Category } from '@robustastudio/e-commerce/common';

type MenuItemSelection = {
  type: 'Category' | 'Brand';
  payload: Brand | Category | null;
};

const isOpen = ref(false);
const { data } = useAsyncData(async () => {
  const payload = await useCategories();

  const brands = await useBrands();

  return {
    flatCategories: payload.flatCategories,
    brands: brands.brands,
  };
});

const flatCategories = computed(() => data.value?.flatCategories || []);

const { redirect } = useAppRouter();
const route = useRoute();

const selectedMenuItem = ref<MenuItemSelection | null>(null);

const { t: $t } = useI18n({
  useScope: 'local',
});

useEvent('TOGGLE_CATEGORY_MENU', () => {
  isOpen.value = !isOpen.value;
});

function close() {
  isOpen.value = false;
}

function navigateTo(url: string) {
  close();

  redirect(url);
}
watch(
  () => route.path,
  () => {
    close();
  },
);

watchEffect(() => {
  if (process.server) {
    return;
  }

  window.document.body.classList.toggle('overflow-hidden', isOpen.value);
});

onBeforeUnmount(() => window.document.body.classList.toggle('overflow-hidden', false));

const categories = computed(() => {
  return toRaw(flatCategories.value)
    ?.filter(category => category.includeInMenu && category.level === 1)
    .slice(0, 9);
});
</script>
<style lang="postcss" scoped>
.Category__Menu > li:not(:last-of-type)::after {
  @apply flex flex-col bg-deals-45 opacity-50 my-5;
  content: ' ';
  width: 100%;
  height: 1px;
}

.Subategory__Menu > li::after {
  @apply flex flex-col bg-deals-45 opacity-50 my-5;
  content: ' ';
  width: 100%;
  height: 1px;
}

.SaleIcon {
  @apply block relative mr-6;

  &::before,
  &::after {
    @apply absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-deals-35 rounded-full;
    content: '';
  }

  &::before {
    @apply opacity-10 w-10 h-10;
  }

  &::after {
    @apply opacity-20 w-8 h-8;
  }
}

.NewIcon {
  @apply block relative mr-6;

  &::before,
  &::after {
    @apply absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-deals-35 rounded-full;
    content: '';
  }

  &::before {
    @apply opacity-10 w-10 h-10;
  }

  &::after {
    @apply opacity-20 w-8 h-8;
  }
}

.StackMenu {
  @apply absolute bottom-0  right-0;
  top: 0;
  width: 100vw;
  /* min-height: var(--window-height); */
  height: calc(var(--screen-height) - 124px);
}

.category-name {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.dialog-slider {
  height: calc(var(--screen-height) - 40px);
}
</style>

<i18n>
{
  "en": {
    "title": "Shop By Category",
    "categories": "Categories",
    "close":"Close",
    "closeMenu":"Close Menu",
    "whatYouLookingFor": "What are you Looking For ?",
    "shopByBrand": "Shop By Brand",
    "shopAllBrands": "Shop all BRANDS A-Z",
    "ourPartners": "Our Partners in success",
    "allCategories": "All Categories",
    "shopAll": "Shop All {name}",
    "sale": "Sale",
    "newIn": "New In"
  },
  "ar": {
    "title": "الفئات",
    "categories": "الفئات",
    "close":"اغلاق",
    "closeMenu":"القائمة اغلاق",
    "whatYouLookingFor": "عن ماذا تبحث بالضبط?",
    "shopByBrand": "تصفح حسب الماركه",
    "shopAllBrands": "تسوق جميع الماركات",
    "ourPartners": "شركاؤنا فى النجاح ",
    "allCategories": "جميع الفئات",
    "shopAll": "تسوق جميع {name}",
    "sale": "تخفيضات",
    "newIn": "جديد"
  }
}
</i18n>
