<template>
  <transition name="alert">
    <div v-if="isOpen" class="fixed top-16 h-full right-0 left-0 mx-auto container pointer-events-none">
      <AddToCartAlert v-if="isOpen && item" :product="item" @close="close" />
    </div>
  </transition>
</template>

<script setup lang="ts">
import type { ProductData } from '@robustastudio/e-commerce/common';

const item = ref();
const { isOpen, toggle } = useDropdown();
function close() {
  toggle(false);
}

useEvent('ITEM_ADDED', (product: ProductData) => {
  item.value = product;
  toggle(true);

  setTimeout(close, 4000);
});
</script>

<style lang="postcss" scoped>
.AddToCartAlert {
  @apply top-25;
  width: calc(100vw - 60px);
  height: auto;
  right: -15px;

  &::before {
    @apply bg-primary-1-100 absolute;
    content: '';
    right: 20px;
    top: -7px;
    transform: rotate(45deg);
    width: 15px;
    height: 15px;
  }

  &__close {
    @apply outline-none;
  }
}

@screen lg {
  .AddToCartAlert {
    width: 362px;
  }
}
</style>

<i18n>
{
  "en": {
    "itemAdded": "{name} has been added to cart",
    "viewCart": "View Cart",
    "close": "Close"
  },
  "ar": {
    "itemAdded": "تمت اضافة {name} الى عربة التسوق",
    "viewCart": "عربة التسوق",
    "close": "اغلاق"
  }
}
</i18n>
