<template>
  <VariantModalDialog v-model="isShown">
    <div class="p-7">
      <h2 class="font-body font-medium text-center text-2xl text-primary-1-100">{{ $t('title') }}</h2>
      <p class="mt-3 text-primary-1-100 text-sm text-center">{{ $t('message') }}</p>

      <AppButton as="AppLink" to="/login" class="mx-auto justify-center mt-8 w-1/3">{{ $t('cta') }}</AppButton>
    </div>
  </VariantModalDialog>
</template>
w
<script setup lang="ts">
const isShown = ref(false);
useEvent('AUTH_REQUIRED', () => {
  isShown.value = true;
});
const { t: $t } = useI18n({
  useScope: 'local',
});
</script>

<i18n>
{
  "en": {
    "title": "Login Required",
    "message": "You must be logged in to perform this action",
    "cta": "Login"
  },
  "ar": {
    "title": "تسجيل الدخول",
    "message": "يجب عليك تسجيل الدخول للقيام بهذه العمليه",
    "cta": "تسجيل الدخول"
  }
}
</i18n>
