<template>
  <transition-group name="slide-up">
    <div v-if="isOpen" :key="1" class="z-30 fixed inset-x-0 top-8 bottom-0 dialog-slider">
      <div class="absolute inset-0 w-full h-full bg-black opacity-50 z-10" />

      <div class="absolute z-20 w-full rounded-t-sm h-full flex flex-col bg-primary-50 lg:hidden">
        <button
          class="text-sm [ bg-gray-100 rounded-sm ] [ flex items-center justify-center ] [ w-8 h-8 ] [ absolute top-5 right-5 ] z-50"
          @click="close"
        >
          <svg-icon-close class="text-primary-1-100" />
        </button>

        <div v-if="user" class="relative pt-4 pb-8 h-full overflow-y-auto">
          <header class="w-full [ text-primary-1-100 relative ] [ pb-7 px-5 z-0 ] [ flex flex-col justify-between ]">
            <SectionTitle class="mt-6 text-sm md:justify-center" dir="left">
              {{ $t('welcome') }}
            </SectionTitle>

            <h3 class="mt-2 text-xl-1 text-black">{{ user.firstname }} {{ user.lastname }}</h3>
          </header>

          <ul v-if="user" class="flex items-center justify-between space-x-5 px-5 md:px-0">
            <li class="wishlist-item__bg flex items-center flex-1 flex-col py-3 rounded-6sm">
              <span class="text-xl"> {{ user.totalWishlist }} </span>

              <span class="">
                {{ $t('wishlistItems') }}
              </span>
            </li>

            <li class="orders-item__bg flex items-center flex-1 flex-col py-3 rounded-6sm">
              <span class="text-xl"> {{ user.totalOrders }} </span>

              <span class="">
                {{ $t('previousOrders') }}
              </span>
            </li>
          </ul>

          <ul class="Category__Menu px-5">
            <li class="Category__Menu__Item">
              <button
                :aria-label="`navigato to `"
                class="flex w-full items-center justify-between"
                @click="navigateTo('/account/wishlist')"
              >
                <div class="flex items-center">
                  <svg-icon-favorite-dimmed alt="category image" class="mr-4 object-contain" />
                  <span class="font-normal"> {{ $t('myWishlist') }} </span>
                </div>
                <svg-icon-tailed-arrow
                  class="float-right text-primary-1-100 fill-current"
                  :class="{
                    'transform scale-x-reverse': $i18n.locale === 'ar',
                  }"
                  width="16"
                  height="16"
                />
              </button>
            </li>
            <li class="Category__Menu__Item">
              <button
                :aria-label="`navigato to `"
                class="flex w-full items-center justify-between"
                @click="navigateTo('/account/orders')"
              >
                <div class="flex items-center">
                  <svg-icon-delivery-active alt="category image" class="mr-4 object-contain" />
                  <span class="font-normal"> {{ $t('myOrders') }} </span>
                </div>
                <svg-icon-tailed-arrow
                  class="float-right text-primary-1-100 fill-current"
                  :class="{
                    'transform scale-x-reverse': $i18n.locale === 'ar',
                  }"
                  width="16"
                  height="16"
                />
              </button>
            </li>

            <li class="Category__Menu__Item">
              <button
                :aria-label="`navigato to address book `"
                class="flex w-full items-center justify-between"
                @click="navigateTo('/account/addresses')"
              >
                <div class="flex items-center">
                  <svg-icon-address-active alt="category image" class="mr-4 object-contain" />
                  <span class="font-normal"> {{ $t('myAddressBook') }} </span>
                </div>
                <svg-icon-tailed-arrow
                  class="float-right text-primary-1-100 fill-current"
                  :class="{
                    'transform scale-x-reverse': $i18n.locale === 'ar',
                  }"
                  width="16"
                  height="16"
                />
              </button>
            </li>

            <li class="Category__Menu__Item">
              <button
                :aria-label="`navigato to `"
                class="flex w-full items-center justify-between"
                @click="navigateTo('/account/edit')"
              >
                <div class="flex items-center">
                  <svg-icon-settings-active alt="category image" class="mr-4 object-contain" />
                  <span class="font-normal"> {{ $t('accountSetting') }} </span>
                </div>
                <svg-icon-tailed-arrow
                  class="float-right text-primary-1-100 fill-current"
                  :class="{
                    'transform scale-x-reverse': $i18n.locale === 'ar',
                  }"
                  width="16"
                  height="16"
                />
              </button>
            </li>
          </ul>

          <div class="my-4 w-full h-1 bg-gray-100" />
          <ul class="Category__Menu px-5">
            <li class="Category__Menu__Item">
              <button :aria-label="`change language`" class="flex w-full items-center justify-between">
                <div class="flex items-center">
                  <svg-icon-global-active alt="category image" class="mr-4 object-contain" />
                  <span class="font-normal"> {{ $t('changeLanguage') }} </span>
                </div>

                <a :href="switchLocalePath($i18n.locale === 'en' ? 'ar' : 'en')">{{ $t('switchLang') }}</a>
              </button>
            </li>

            <li class="Category__Menu__Item">
              <button :aria-label="`navigato to `" class="flex w-full items-center justify-between" @click="doLogout">
                <div class="flex items-center">
                  <svg-icon-logout-filled class="mr-2" width="20" height="20" />
                  <span class="font-normal text-red-700"> {{ $t('logout') }} </span>
                </div>
              </button>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </transition-group>
</template>

<script setup lang="ts">
const { user } = useAuth();
const { logout } = useLogout();

const isOpen = ref(false);
const { redirect } = useAppRouter();
const route = useRoute();

const { t: $t } = useI18n({
  useScope: 'local',
});

useEvent('TOGGLE_ACCOUNT_MENU', () => {
  isOpen.value = true;
});

function close() {
  isOpen.value = false;
}

function navigateTo(url: string) {
  close();
  redirect(url);
}

watchEffect(() => {
  if (process.server) {
    return;
  }

  window.document.body.classList.toggle('overflow-hidden', isOpen.value);
});

onBeforeUnmount(() => window.document.body.classList.toggle('overflow-hidden', false));

watch(route, () => {
  close();
});
const switchLocalePath = useSwitchLocalePath();

function doLogout() {
  logout();
  close();
}
</script>

<style lang="postcss" scoped>
.Category__Menu > li:not(:last-child) {
  &::after {
    @apply flex flex-col bg-primary-300 mt-6 mb-4;
    content: ' ';
    width: 100%;
    height: 1px;
  }
}

.Category__Menu > li {
  @apply pt-4;
}

.StackMenu {
  @apply absolute bottom-0  right-0;
  top: 2.5rem;
  width: 100vw;
  max-height: calc(var(--window-height) - 50px);
}

.category-name {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.dialog-slider {
  @apply pb-20;
  height: calc(var(--screen-height) - 100px);
}

.wishlist-item__bg {
  background: #f9e9ed;
  background-repeat: no-repeat;
  background-position: 0 0;
}

.orders-item__bg {
  background: #f6f3ee;
  background-repeat: no-repeat;
  background-position: 0 0;
}
</style>

<i18n>
{
  "en": {
    "welcome": "Welcome",
    "categories": "Categories",
    "close":"Close",
    "whatYouLookingFor": "What are you Looking For ?",
    "shopByBrand": "Shop By Brand",
    "ourPartners": "Our Partners in success",
    "allCategories": "All Categories",
    "myWishlist": "MY Wishlist",
    "myOrders": "My Orders",
    "myAddressBook": "My AddressBook",
    "accountSetting": "Account Settings",
    "switchLang": "عربي", 
    "changeLanguage": "Change language",
    "logout": "Logout",
    "previousOrders": "Previous Orders",
    "wishlistItems": "Wishlist Items"
  },
  "ar": {
    "welcome": "أهلا",
    "categories": "الفئات",
    "close":"اغلاق",
    "whatYouLookingFor": "عن ماذا تبحث بالضبط?",
    "shopByBrand": "تصفح حسب الماركه",
    "ourPartners": "شركاؤنا فى النجاح ",
    "allCategories": "جميع الفئات"
  }
}
</i18n>
