<template>
  <div>
    <div class="flex items-center space-x-4 w-full h-full">
      <div class="flex w-full items-center">
        <form method="get" :action="`/${locale}/search`" class="w-full">
          <TextInput
            id="search-dialog"
            class="outline-none text-black border-none md:border"
            type="search"
            name="q"
            autocomplete="off"
            :placeholder="$t('searchProducts')"
            :aria-label="$t('searchProducts')"
            :is-optional="false"
            icon="search"
            filled
            rounded
            dark
            @focus="toggleDefinedAlias('searchDialog')"
          >
            <template #icon>
              <svg-icon-search class="text-primary-1-100 [ absolute left-3 z-10 ] underline" width="21" height="21" />
            </template>
          </TextInput>

          <button type="submit" class="hidden" aria-label="submit" />
        </form>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
const { locale } = useI18n();
const { t: $t } = useI18n({
  useScope: 'local',
});

const { toggleDefinedAlias } = useBottomSheet();
</script>

<style lang="postcss" scoped>
:deep(input::placeholder) {
  @apply text-primary-1-100;
}

.common-search-list a {
  @apply flex justify-between items-center;
}

.search-result li:not(:last-child) {
  @apply border-b border-primary-300;
}
.search-suggestions__list {
  @apply flex items-center flex-wrap gap-3;
}

.dialog-slider {
  max-height: 100vh;
}

.product-name {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
</style>
<i18n>
{
  "en": {
    "searchProducts": "Search products",
    "suggestedProducts": "Search Suggestions",
    "suggestedCategories": "Categories",
    "suggestedBrands": "Brands",
    "noSearchFor": "No Search Results for “{string}” ",
    "noSearchDescription": "Double-check your spelling, use more generic terms, try different keywords & you can refine your search later",
    "enhanceResults": "To Enhance your search",
    "enhanceNote": "To enhance the accuracy of your search query, it is recommended to verify the spelling of all included words and to experiment with various keywords"
  },
  "ar": {
    "searchProducts": "البحث عن المنتجات",
    "suggestedProducts": "اقتراحات البحث",
    "suggestedCategories": "الاقسام",
    "suggestedBrands": "الماركات",
    "noSearchFor": "لا توجد نتائج ل “{string}” ",
    "noSearchDescription": "تحقق جيدًا من الإملاء ، واستخدم مصطلحات عامة ، وجرب كلمات رئيسية مختلفة ، ويمكنك تحسين البحث لاحقًا",
    "enhanceResults": "لتحسين البحث",
    "enhanceNote": "تأكد من الكلمات المستخدمة في البحث أو استخدم عبارات أخرى أو حاول بحث بعبارات أخرى للحصول على المحتوى المطلوب"
  }
}
</i18n>
